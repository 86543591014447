import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import parse from 'html-react-parser';
import Logo from '../../../components/shared/logo';
import FooterMenu from './footer-menu';
import Text from '../../../components/shared/text';
import Heading from '../../../components/shared/heading';
import Link from '../../../components/shared/link';
import Img from 'gatsby-image';
import Social, { SocialLink } from '../../../components/shared/social';
import {
  FooterWrap,
  FooterTop,
  FooterMiddle,
  FooterMiddleLeft,
  FooterMiddleRight,
  AddressWrap,
  Address,
  ScrollTop,
  FooterBottom,
  FooterBottomLeft,
  FooterBottomRight,
} from './footer.stc';

const Footer = ({
  logoStyle,
  headingStyle,
  emailTextStyle,
  emailLinkStyle,
  widgetTitleStyle,
  addresTextStyle,
  addresSpanStyle,
  copyrightStyle,
  socialStyle,
}) => {
  const scrollHandler = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const siteQueryData = useStaticQuery(graphql`
    query footerSiteDataQuery {
      site {
        siteMetadata {
          copyright
          contact {
            postal_code
            address
            email
            social {
              facebook
              linkedin
              twitter
              dribbble
            }
          }
        }
      }
    }
  `);

  const { copyright, contact } = siteQueryData.site.siteMetadata;
  const {
    postal_code,
    email,
    address,
    social: { facebook, twitter, linkedin, dribbble },
  } = contact;
  const LinkedinImage = graphql`
    query {
      file(relativePath: { eq: "../../images/LI-Logo.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fixed(width: 125, height: 125) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `;

  return (
    <FooterWrap>
      <FooterTop>
        <Logo {...logoStyle} />
        <div className='ft-menu'>
          <FooterMenu />
        </div>
      </FooterTop>
      <FooterMiddle>
        <FooterMiddleLeft>
          <Heading {...headingStyle}>Conversa con Nosotros!</Heading>
          {email && (
            <Text {...emailTextStyle}>
              <Link {...emailLinkStyle}>contacto@mho.cl</Link>
            </Text>
          )}
        </FooterMiddleLeft>
        <FooterMiddleRight>
          <AddressWrap>
            {postal_code && (
              <Address>
                <Heading {...widgetTitleStyle}>Chile</Heading>
                <Text {...addresTextStyle}>
                  <Text {...addresSpanStyle}>
                    <strong>Dirección:</strong>
                  </Text>
                  <Text {...addresSpanStyle}>
                    Presidente Riesco 5335, Oficina 606, Las Condes, Región
                    Metropolitana
                  </Text>
                  <Text {...addresSpanStyle}>
                    <strong>Teléfono:</strong>
                  </Text>
                  <Text {...addresSpanStyle}>+(56 2) 265 71625</Text>
                </Text>
              </Address>
            )}
            {address && (
              <Address>
                <Heading {...widgetTitleStyle}>Perú</Heading>
                <Text {...addresTextStyle}>
                  <Text {...addresSpanStyle}>
                    <strong>Dirección:</strong>
                  </Text>
                  <Text {...addresSpanStyle}>
                    Edificio Bussines Club Torre 1, Calle Bolivar 472 oficina
                    1206, Miraflores – Lima
                  </Text>
                  <Text {...addresSpanStyle}>
                    <strong>Teléfono:</strong>
                  </Text>
                  <Text {...addresSpanStyle}>+(51 1) 3968053</Text>
                </Text>
              </Address>
            )}
          </AddressWrap>
          <ScrollTop className='creative-cursor' onClick={scrollHandler}>
            <div className='arrow-top'></div>
            <div className='arrow-top-line'></div>
          </ScrollTop>
        </FooterMiddleRight>
      </FooterMiddle>
      <FooterBottom>
        {copyright && (
          <FooterBottomLeft>
            <Text {...copyrightStyle}>
              {new Date().getFullYear()} {copyright}
            </Text>
          </FooterBottomLeft>
        )}
        <FooterBottomRight>
          <Social {...socialStyle}>
            {linkedin && (
              <a href={linkedin}>
                <img
                  style={{
                    background: 'white',
                    width: '120px',
                    padding: '10px 10px',
                    borderRadius: '5px',
                  }}
                  src='https://content.linkedin.com/content/dam/me/business/en-us/amp/brand-site/v2/bg/LI-Logo.svg.original.svg'
                />
              </a>
            )}
            {/* {facebook && <SocialLink path={facebook}>Facebook</SocialLink>}
						{twitter && <SocialLink path={twitter}>Twiiter</SocialLink>} */}
            {/* {linkedin && <SocialLink path={linkedin}>Linkedin</SocialLink>} */}
            {/* {dribbble && <SocialLink path={dribbble}>Dribbble </SocialLink>} */}
          </Social>
        </FooterBottomRight>
      </FooterBottom>
    </FooterWrap>
  );
};

Footer.propTypes = {
  logoStyle: PropTypes.object,
  headingStyle: PropTypes.object,
  emailTextStyle: PropTypes.object,
  emailLinkStyle: PropTypes.object,
  widgetTitleStyle: PropTypes.object,
  addresTextStyle: PropTypes.object,
  addresSpanStyle: PropTypes.object,
  copyrightStyle: PropTypes.object,
  socialStyle: PropTypes.object,
};

Footer.defaultProps = {
  logoStyle: {
    responsive: {
      medium: {
        maxwidth: '125px',
      },
    },
  },
  headingStyle: {
    as: 'h1',
    color: '#f8f8f8',
    fontSize: '100px',
    fontweight: 700,
    lineHeight: 1,
    responsive: {
      xlarge: {
        fontSize: '65px',
      },
      medium: {
        fontSize: '30px',
      },
    },
  },
  emailTextStyle: {
    m: 0,
  },
  emailLinkStyle: {
    color: '#fff',
    fontSize: '30px',
    fontweight: 500,
    opacity: 0.7,
    responsive: {
      xlarge: {
        fontSize: '20px',
      },
      medium: {
        fontSize: '16px',
      },
    },
  },
  widgetTitleStyle: {
    color: '#c1c1c1',
    fontSize: '16px',
    fontweight: 600,
    texttransform: 'uppercase',
    letterspacing: '1px',
    responsive: {
      small: {
        fontSize: '14px',
      },
    },
  },
  addresTextStyle: {
    color: '#fff',
    opacity: 0.7,
    responsive: {
      medium: {
        fontSize: '12px',
        lineHeight: '24px',
      },
    },
  },
  addresSpanStyle: {
    as: 'span',
    display: 'block',
  },
  copyrightStyle: {
    color: '#fff',
    m: 0,
    fontSize: '14px',
    letterspacing: '1px',
    fontweight: 400,
    opacity: 0.7,
    responsive: {
      medium: {
        fontSize: '10px',
        letterspacing: '0.5px',
      },
    },
  },
  socialStyle: {
    opacity: 0.7,
    space: 30,
    responsive: {
      medium: {
        space: 20,
        fontSize: '12px',
      },
    },
  },
};

export default Footer;
