import styled from "styled-components";
import { device } from "../../../../theme";

export const LinkCustom = styled.a`
  display: block;
  color: #fff;
  font-size: 16px !important;
  line-height: normal;
  padding: 0.5vh 0;
  transition: 0.3s;
  font-weight: 700;
  opacity: 1;
  text-transform: capitalize;
  &:visited {
    color: #fff;
  }
  @media ${device.medium} {
    font-size: 3vw;
  }
  @media ${device.xsmall} {
    font-size: 5vw;
  }
  &:hover {
    color: #fff;
  }
  &.active {
    color: #fff;
  }
`;
