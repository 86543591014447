import React from "react";
import PropTypes from "prop-types";
import { NavLinkWrap } from "./navlink.stc";
import { LinkCustom } from "./link.stc";

export const NavLink = ({ path, children, ...props }) => {
  if (path.includes("http")) {
    return (
      <LinkCustom
        href={path}
        className="big-cursor"
        activeClassName="active"
        {...props}
      >
        {children}
      </LinkCustom>
    );
  }
  return (
    <NavLinkWrap
      to={path}
      className="big-cursor"
      activeClassName="active"
      {...props}
    >
      {children}
    </NavLinkWrap>
  );
};

NavLink.propTypes = {
  children: PropTypes.node.isRequired,
  path: PropTypes.string,
};

NavLink.defaultProps = {
  path: "/",
};
