import React from 'react';
import { Link } from 'gatsby';
import { FooterMenuWrap, FooterMenuList } from './footer-menu.stc';

const FooterMenu = () => {
  return (
    <div></div>
    // <FooterMenuWrap>
    //   <FooterMenuList>
    //     <Link to="/" className="no-cursor">
    //       Servicios
    //     </Link>
    //   </FooterMenuList>
    //   <FooterMenuList>
    //     <a href="https://mhodev.codyware.com/brochure" className="no-cursor">
    //       Brochure
    //     </a>
    //   </FooterMenuList>
    //   <FooterMenuList>
    //     <Link to="/" className="no-cursor">
    //       Nuestros Clientes
    //     </Link>
    //   </FooterMenuList>
    //   <FooterMenuList>
    //     <Link to="/" className="no-cursor">
    //       Trabaja con Nosotros
    //     </Link>
    //   </FooterMenuList>
    //   <FooterMenuList>
    //     <Link to="/" className="no-cursor">
    //       Contacto
    //     </Link>
    //   </FooterMenuList>
    // </FooterMenuWrap>
  );
};

export default FooterMenu;
